import { PhotoState } from "@earthtoday/contract";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import Router from "next/router";
import React, { ReactNode, useEffect, useRef } from "react";

import { etStormGray, etWhite } from "../../shared/colors";
import {
  DATE_FORMAT_MMMM_DD,
  DATE_FORMAT_MMMM_DD_YYYY,
} from "../../shared/constants";
import { formatProtected } from "../../shared/helpers/formatProtectedNumber";
import { isCurrentYear } from "../../shared/helpers/isCurrentYear";
import { pluralize } from "../../shared/helpers/pluralize";
import {
  GroupInfo,
  GroupMemberRole,
  IUserCountsCustom,
  UserType,
} from "../../shared/models/User";
import { useTranslation } from "../../shared/translate/NextI18next";
import { ModalType } from "../../stores/ModalStore";
import { Button } from "../Button/Button";
import { GroupCardMenuContext } from "../CardGroupMenuContext/GroupCardMenuContext";
import { CardItemLocation } from "../CardItem/CardItem";
import { ContextMenuRowReport } from "../ContextMenuRow/ContextMenuRowReport";
import { DangerouslyHTMLRenderer } from "../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import { HydratedDateWrapper } from "../HydratedDateWrapper/HydratedDateWrapper";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardProfile.styled";
import { CardProfileBio } from "./CardProfileBio";
import { CardProfileFooter } from "./CardProfileFooter";
import { CardProfileTitle } from "./CardProfileTitle";

export interface UserProfileCard {
  vanityName: string;
  fullName: string;
  joined: Date;
  userCounts: IUserCountsCustom;
  bio?: string;
  twitterUsername?: string;
  facebookUsername?: string;
  instagramUsername?: string;
  linkedInUsername?: string;
  emailAddress: string;
  website?: string;
  image: string;
  userType: UserType;
  isPublished?: boolean;
  userSubTitle: string;
  tagline?: string;
  profileTagOverride?: string;
  isEmailPublic?: boolean;
  allowSwitchToken: boolean;
  groupMemberRole?: GroupMemberRole;
  group?: GroupInfo;
  isOwnerView: boolean;
  photoState: PhotoState;
  shouldEnableImageUploadZone: boolean;
  shouldRenderNewImageButton: boolean;
  onCardProfileMount(location: CardItemLocation);
  onProfileImageZoneClicked(): void;
  switchToken(redirect?: () => void): void;
}

export interface ScrollController {
  forceScrollForProfile: boolean;
  updateScrollPermission(target: "tabs" | "profile", permission: boolean): void;
}

type Props = {
  driver: UserProfileCard;
  scrollController: ScrollController;
  contextMenu: { active: boolean };
  noOverlayLinkAndPublishBtn?: boolean; //only use on uon open page
  location?: CardItemLocation;
  featureFlaggingStore: {
    flags: {
      enableCollectableUon: boolean;
    };
  };
  openPublishModal?(): void;
  openModal(modalTypes: ModalType): void;
  renderMenuContext(menuItem: ReactNode): ReactNode;
};

export const CardProfile = observer(function CardProfile(props: Props) {
  const { t } = useTranslation("ProfileCard");
  const { t: tDeckGeneral } = useTranslation("DeckGeneral");

  const ref = useRef<any>(null);
  const {
    driver,
    scrollController,
    openPublishModal,
    openModal,
    noOverlayLinkAndPublishBtn,
    featureFlaggingStore,
    location,
  } = props;
  const uonCount = driver.userCounts.uonCount || 0;
  const { image } = driver;

  useEffect(() => {
    if (!location) {
      return;
    }

    driver.onCardProfileMount(location);
  }, [location]);

  useEffect(() => {
    if (scrollController.forceScrollForProfile) {
      // eslint-disable-next-line no-unused-expressions
      ref.current &&
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
    }
    scrollController.updateScrollPermission("profile", false);
  }, [scrollController.forceScrollForProfile]);

  const renderPublishedContent = () => {
    return (
      <>
        <sc.Joined data-cy="CardProfile-Joined">
          {t("profilecard.member-since")}{" "}
          <HydratedDateWrapper>
            {isCurrentYear(new Date(driver.joined))
              ? format(new Date(driver.joined), DATE_FORMAT_MMMM_DD)
              : format(new Date(driver.joined), DATE_FORMAT_MMMM_DD_YYYY)}
          </HydratedDateWrapper>
        </sc.Joined>
        <sc.UserCounts data-cy="CardProfile-UserCounts">
          <sc.DeckCounts data-cy="CardProfile-DeckCounts">
            {pluralize(
              "Deck",
              driver.userCounts.curatedDecks || 0,
              tDeckGeneral,
            )}
          </sc.DeckCounts>
          {" - "}
          <sc.FollowerCounts data-cy="CardProfile-FollowerCounts">
            {pluralize(
              "Follower",
              driver.userCounts.followerCounts || 0,
              tDeckGeneral,
            )}
          </sc.FollowerCounts>
        </sc.UserCounts>
        <CardProfileFooter driver={driver} />
      </>
    );
  };

  const renderUnpublishedContent = () => {
    return (
      <sc.UnpublishContent>
        <sc.UnpublishedMessage data-cy="CardProfile-UnpublishedMsg">
          {t("profilecard.unpublish-msg")}
        </sc.UnpublishedMessage>
        <sc.ButtonWrapper>
          <Button
            driver={{
              dataCy: "CardProfile-ButtonPublish",
              type: "primary",
              fontSize: "16px",
              fontWeight: 500,
              height: "40px",
              width: "280px",
              onClick: openPublishModal,
              disabled: driver.groupMemberRole === GroupMemberRole.CURATOR,
            }}
          >
            {t("profilecard.button.publish-group")}
          </Button>
        </sc.ButtonWrapper>
      </sc.UnpublishContent>
    );
  };

  const renderFooter = () => {
    if (driver.allowSwitchToken)
      return (
        <sc.SwitchTokenContainer>
          <Button
            driver={{
              type: "primary",
              dataCy: "CardProfile-SwitchButton",
              width: "280px",
              height: "40px",
              color: etWhite,
              fontFamily: "Roboto",
              mobileVisible: true,
              fontSize: "16px",
              action: "button",
              textTransform: "none",
              onClick: () =>
                driver.switchToken(() => {
                  Router.push("/" + driver.vanityName, undefined, {
                    shallow: true,
                  });
                }),
            }}
          >
            {t("profilecard.button.switch-to-group")}
          </Button>
        </sc.SwitchTokenContainer>
      );

    // only show for group token
    if (
      !driver.isPublished &&
      driver.userType === UserType.GROUP_ADAPTER &&
      !noOverlayLinkAndPublishBtn
    ) {
      return renderUnpublishedContent();
    }
    return renderPublishedContent();
  };

  return (
    <sc.Container
      data-cy="CardProfile-Container"
      className="visual-reg"
      ref={ref}
    >
      <sc.Content>
        {!noOverlayLinkAndPublishBtn &&
          (driver.allowSwitchToken ? (
            <sc.LinkProfileSwitchToken
              data-cy="link-profile"
              onClick={() =>
                driver.switchToken(() =>
                  Router.push("/" + driver.vanityName, undefined, {
                    shallow: true,
                  }),
                )
              }
            />
          ) : (
            <Link href={`/${driver.vanityName}`}>
              <sc.LinkProfile data-cy="link-profile" />
            </Link>
          ))}
        <sc.ContextWrapper contextMenuActive={props.contextMenu.active}>
          {props.renderMenuContext(
            driver.allowSwitchToken ? (
              <GroupCardMenuContext driver={driver} />
            ) : (
              <ContextMenuRowReport openModal={openModal} />
            ),
          )}
        </sc.ContextWrapper>
        {props.contextMenu.active === true ? (
          <sc.OverlayContextMenu />
        ) : undefined}
        <sc.CardHeaderImgWrapper>
          <sc.IconContainer>
            <IconSvg
              icon="m2-icon"
              width="40px"
              height="40px"
              color={etStormGray}
            />
          </sc.IconContainer>
          {uonCount ? (
            <sc.ProtectedNumber data-cy="CardProfile-ProtectedNumber">
              {uonCount > 0 ? formatProtected(uonCount) : ""}
            </sc.ProtectedNumber>
          ) : undefined}
          {featureFlaggingStore.flags.enableCollectableUon &&
          driver.shouldRenderNewImageButton ? (
            <sc.CardHeaderImgCrossair
              data-cy="CardProfile-Crossair"
              src={require("../../assets/img/cross-hair-profile-card.png")}
              pointer={driver.shouldEnableImageUploadZone}
              onClick={driver.onProfileImageZoneClicked}
            />
          ) : (
            <sc.CardHeaderImg
              data-cy="CardProfile-Avatar"
              zIndex={driver.shouldEnableImageUploadZone ? 10 : 0}
              pointer={driver.shouldEnableImageUploadZone}
              onClick={driver.onProfileImageZoneClicked}
              src={image}
            />
          )}
        </sc.CardHeaderImgWrapper>

        <sc.CardContentWrapper>
          <CardProfileTitle driver={driver} />
          {featureFlaggingStore.flags.enableCollectableUon &&
          driver.shouldRenderNewImageButton ? (
            <sc.UploadImageDescription data-cy="CardProfile-UploadImageDescription">
              <DangerouslyHTMLRenderer>
                {t("profilecard.upload-image-description", {
                  name: driver.fullName,
                })}
              </DangerouslyHTMLRenderer>
            </sc.UploadImageDescription>
          ) : (
            <CardProfileBio driver={driver} />
          )}
        </sc.CardContentWrapper>
        {renderFooter()}
      </sc.Content>
    </sc.Container>
  );
});
